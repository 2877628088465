import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import close from '../../assets/icons/close.svg'
import { get, post } from '../../utils/request'
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import '../../App.css'
import { format } from 'date-fns';
import PDFImageViewer from '../product/PDFImageViewer'
// import samplepdf from "../../assets/files/promotest.pdf"
console.log('pdfjs worker:' + pdfjs.version)

const PromoByProductPopup = (props) => {
  const { visitId } = useSelector(state => state?.visitPlans?.visitPlanDetail || '')
  const auth = useSelector(state => state.auth.user)
  const [popup, setpopup] = useState(false)
  const [state, setState] = useState({
    promo: [],
    loading: true,
    params: {
      productId: props.id,
      visitId: visitId
    },
    promoLog: {}
  })
  pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.js`;
  // pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const getData = () => {
    setState(prevState => {
      return {
        ...prevState,
        loading: false
      }
    })

    get(
      `/api/v1/api/promo-by-product`,
      {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          'Content-Type': 'application/json'
        },
        params: {
          productId: props.id,
          visitId: visitId
        }
      },
      success => {
        setState(prevState => {
          return {
            ...prevState,
            promo: Object.assign([], success.data.data),
            loading: false
          }
        })
      },
      error => {
        console.log('error get data promo by product', error)
      }
    )
  }
  const handlePopup = () => {
    setpopup(!popup)
    return getData()
  }

  const closePopup = () => {
    const rawUpdate = {
      endTime: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      visitId: visitId,
      materialCode: props.materialCode
    }

    if (Object.keys(state.promoLog).length > 0) {
      updatePromoLog(rawUpdate)
    }

    setpopup(!popup)
  }

  const openFile = (url, promoId, materialCode) => {
    const rawStore = {
      promoId: promoId,
      startTime: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      visitId: visitId,
      materialCode: materialCode
    }

    const rawUpdate = {
      endTime: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      visitId: visitId,
      materialCode: materialCode
    }

    if (Object.keys(state.promoLog).length === 0) {
      storePromoLog(rawStore)
    }

    if (Object.keys(state.promoLog).length > 0) {
      updatePromoLog(rawUpdate)
      storePromoLog(rawStore)
    }

    window.open(url, "_blank");
  }


  const storePromoLog = (raw) => {
    post(
      `/api/v1/promo-log/store`,
      {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          'Content-Type': 'application/json'
        }
      },
      raw,
      success => {
        setState(prevState => {
          return {
            ...prevState,
            promoLog: Object.assign({}, success.data.data),
          }
        })
      },
      error => {
        console.log('error store promo log', error)
      }
    )
  }

  const updatePromoLog = (raw) => {
    post(
      `/api/v1/promo-log/update/` + state.promoLog.promoLogId,
      {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          'Content-Type': 'application/json'
        }
      },
      raw,
      success => {
        setState(prevState => {
          return {
            ...prevState,
            promoLog: {},
          }
        })
      },
      error => {
        console.log('error update promo log', error)
      }
    )
  }

  const getFileExt = (url) => {
    return url.substring(url.lastIndexOf('.') + 1, url.length) || url
  }

  const dateFormat = (date) => {
    let newDate = new Date(date)
    let formated = format(newDate, 'd LLLL y')
    return formated
  }

  const { promo, loading } = state

  return (
    <>
      <span className="cursor-pointer text-secondary hover:underline" onClick={handlePopup}>{props.promo}</span>
      {popup && (
        <div className="fixed w-full h-full flex flex-wrap items-center justify-center top-0 left-0 py-10 overflow-y-auto" style={{ backgroundColor: "rgba(0,0,0,0.5)", fontSize: 16, zIndex: 999999 }}>
          <div className="w-10/12 p-6 bg-white rounded">
            <div className="flex flex-wrap items-center justify-end">
              <div className="w-4 py-1 text-sm text-center text-white rounded-full" onClick={() => closePopup()}>
                <img src={close} alt="close" className="cursor-pointer" />
              </div>
            </div>
            <div className="flex flex-wrap max-w-screen-xl py-2 mx-auto gap-y-10 md:p-4">
              {loading ?
                <div>Loading ....</div>
                : (promo.length > 0 ? promo.map((obj, index) => (
                  <div className="flex w-full bg-white rounded lg:flex-row" key={index}>
                    <div className="flex flex-col justify-end w-1/2" >
                      <div className="flex justify-center rounded cursor-pointer">
                        <div className="flex w-1/2">
                          {getFileExt(obj.promoThumbnail) === 'pdf' ?
                            <PDFImageViewer fileUrl={obj.promoThumbnail} />
                            :
                            <img src={obj.promoThumbnail} alt="close" className="p-2 bg-inputbackground" />
                          }
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col w-6/12 mt-5 text-left gap-y-5 lg:mt-0">
                      <div className="flex flex-col my-auto">
                        <div className="flex items-center justify-between">
                          <div className="text-lg font-bold text-primary">{obj.promoTitle}</div>
                        </div>
                        <div className="font-bold ">{dateFormat(obj.startDate)} - {dateFormat(obj.endDate)}</div>
                        <div className="pt-4 font-bold">Description</div>
                        <div className="">{obj.promoDesc}</div>
                        <div className="py-4 cursor-pointer text-primary" onClick={() => openFile(obj.promoFile, obj.promoId, props.materialCode)}>See more</div>
                      </div>
                    </div>
                  </div>
                )) :
                  <div className="flex w-full bg-white rounded lg:flex-row">
                    <div className="flex justify-center w-full mt-5 lg:mt-0">
                      <p className="text-center ">There are no promo</p>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PromoByProductPopup
