import React from 'react';
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles';
import { COLOR } from '../../constant';

const styled = makeStyles((theme) => ({
  btnRoot: {
    textDecoration: 'none',
    margin: theme.spacing(1),
    borderRadius: 50,
    textAlign: 'center',
    transition: 'none',
    '&:focus': {
      outline: 'none'
    },
  },
  colorPrimary: {
    background: '#204D4C',
    color: '#f1f1f1',
    '&:hover': {
      transition: '.5s',
      background: '#406665',
      color: '#f1f1f1',
    },
  },
  colorYellow: {
    background: '#cedc27',
    color: '#f1f1f1',
    '&:hover': {
      transition: '.5s',
      background: 'linear-gradient(#cedc27, #bac724a3)',
      color: '#f1f1f1',
    },
  },
  colorForiegn: {
    background: '#f1f1f1',
    color: 'black',
    '&:hover': {
      transition: '.5s',
      background: 'linear-gradient(#ffffff, #f0f0f0)',
      color: 'black',
    },
  },
  colorGreen: {
    background: '#719849',
    color: '#f1f1f1',
    '&:hover': {
      transition: '.5s',
      background: 'linear-gradient(#719849, #638640)',
      color: '#f1f1f1',
    },
  },
  colorBlack: {
    background: '#4D4D4D',
    color: '#f1f1f1',
    '&:hover': {
      transition: '.5s',
      background: 'linear-gradient(#4D4D4D, #6e6e6e)',
      color: '#f1f1f1',
    },
  },
  colorBlue: {
    background: '#117577',
    color: '#f1f1f1',
    '&:hover': {
      transition: '.5s',
      background: 'linear-gradient(#117577, #0f6869)',
      color: '#f1f1f1',
    },
  },
  colorRed: {
    background: '#FD0000',
    color: '#f1f1f1',
    '&:hover': {
      transition: '.5s',
      background: 'linear-gradient(#FD0000, #0f6869)',
      color: '#f1f1f1',
    },
  },
}))

const MemoizedButton = React.memo(Button);
const ButtonCustom = ({ color, type, fullWidth, className, onClick, disabled, children }) => {
  const clx = styled();
  const colorType = new Map([
    [COLOR.BLUE, clx.colorBlue],
    [COLOR.GREEN, clx.colorGreen],
    [COLOR.BLACK, clx.colorBlack],
    [COLOR.YELLOW, clx.colorYellow],
    [COLOR.FORIEGN, clx.colorForiegn],
    [COLOR.PRIMARY, clx.colorPrimary],
    [COLOR.RED, clx.colorRed],
  ]).get(color)

  return (
    <MemoizedButton
      type={type}
      fullWidth={fullWidth}
      className={className}
      variant="contained"
      classes={{
        root: clx.btnRoot,
        contained: colorType || clx.colorPrimary
      }}
      onClick={onClick}
      disabled={disabled || false}
    >
      {children}
    </MemoizedButton>
  )
}

export default ButtonCustom