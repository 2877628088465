import { format } from "date-fns";
import { CallPlanningConstant, DATE_FORMAT } from "../../constant"
import { callPlanningActionTypes } from "../constants/actionTypes"

const createOptions = (label, value) => ({ label, value })
const createUnPlanData = (className, customerName, frequency, outletName) => ({ className, customerName, frequency, outletName });
const createMenuOtherWork = (otherworkId, otherworkName, type, leaveDay) => ({ otherworkId, otherworkName, type, leaveDay });
const createSummaryData = (className, reach, volume, notPlanned) => ({ className, reach, volume, notPlanned });
const createTableOtherWork = (id, otherworkId, otherworkName, type, note, date) => ({ id, otherworkId, otherworkName, type, note, date });
const createReviewData = (className, customerName, frequency, planned, status) => ({ className, customerName, frequency, planned, status });
const createTableCustomerVisit = (id, customerId, customerName, className, type, specialty, date, soldToName, frequency, outletCode, soldOutletCode, outletStreet1, outletStreet2, outletStreet3, outletStreet4, outletStreet5) => ({ id, customerId, customerName, className, type, specialty, date, soldToName, frequency, outletCode, soldOutletCode, outletStreet1, outletStreet2, outletStreet3, outletStreet4, outletStreet5 });
const createMenuCustomerVisit = (isNonOutlet, outletCity, customerId, customerName, soldToCode, soldToName, className, actual, target, type, specialty, soldOutletCode, outletCode, outletStreet1, outletStreet2, outletStreet3, outletStreet4, outletStreet5) => ({ isNonOutlet, outletCity, customerId, customerName, soldToCode, soldToName, className, actual, target, type, specialty, soldOutletCode, outletCode, outletStreet1, outletStreet2, outletStreet3, outletStreet4, outletStreet5 });

const initialState = {
	planOfMonth: {
		lstDetail: []
	},
	summaryData: [],
	reviewData: [],
	unPlanData: [],
	customerVisit: [],
	customerVisitFilteredData: [],
	otherWork: [],
	customerClass: [],
	customerType: [],
	tableCustomer: [],
	tableOther: [],
	city: [],
	address: [],
	outletVisitCustomerData: {}
}

export const CallPlanningReducer = (state = initialState, { type, payload = {} }) => {
	switch (type) {
		case callPlanningActionTypes.PLAN_MONTH_DATA: {
			const getInforCalendarStr = (visitCustomer = 0, otherWork = 0, isLeave, isHalfDay) => {
				let count = [];
				if (window.location.pathname === '/slmcalendar') {
					if (otherWork > 0) count.push((isHalfDay === true && otherWork > 1) ? `OW${otherWork - 1}` : `OW${otherWork}`)
				} else {
					if (otherWork > 0) count.push(`${isLeave === true ? `FL` : (isHalfDay === true && otherWork === 1) ? `HL` : (isHalfDay === true && otherWork > 1) ? `HL,OW${otherWork - 1}` : `OW${otherWork}`}`)
				}
				if (visitCustomer > 0) count.push(`C${visitCustomer}`)
				return count.join(', ');
			}


			const countDateBy = (arr = []) => {
				return arr.reduce((acc, { eventDate }) => {
					if (acc[eventDate]) acc[eventDate]++;
					else acc[eventDate] = 1;
					return acc;
				}, {})
			}

			const mapDetailPlanOfMonth = (data = {}) => {
				const objCus = countDateBy(data.visitCustomer);
				const objOth = countDateBy(data.otherWork);
				const arrCus = Object.keys(objCus);
				const arrOth = Object.keys(objOth);

				return [...new Set([...arrCus, ...arrOth])]
					.map(d => {
						const customer = arrCus.find(k => k === d);
						const other = arrOth.find(k => k === d);
						const isLeave = data.otherWork.some(e => e.eventDate === d && e.otherwork?.leaveDay === 1)
						const isHalfDay = data.otherWork.some(e => e.eventDate === d && e.otherwork?.leaveDay === 0.5)

						return {
							date: d,
							isLeave: isLeave,
							isHalfDay: isHalfDay,
							inforCalendar: getInforCalendarStr(objCus[customer], objOth[other], isLeave, isHalfDay),
							customerCount: objCus[customer]
						}
					})
			}

			const getPlanStart = (month, year) => !isNaN(year) && !isNaN(month) && format(new Date(year, month, 1), DATE_FORMAT.YY_MM);
			const getPlanEnd = (month, year) => !isNaN(year) && !isNaN(month) && format(new Date(year, month + 1, 1), DATE_FORMAT.YY_MM);

			const objCallPlanning = (data = {}) => {
				const month = parseInt(data.month) - 1;
				const year = parseInt(data.year);
				return {
					month: month,
					year: year,
					planStart: getPlanStart(month, year),
					planEnd: getPlanEnd(month, year),
					status: data.status,
					callPlanningId: data.callPlanningId,
					memberNik: data.member?.memberNik,
					memberFullname: data.member?.memberFullname,
					lstDetail: mapDetailPlanOfMonth(data)
				}
			}

			const objTemp = payload.data.success ? objCallPlanning(payload.data.data) : {};

			const mapOtherWork = (datas = []) => {
				return datas.map(el => {
					return createTableOtherWork(el.callPlanningEventId, el.otherwork?.otherworkId, el.otherwork?.otherworkName, el.callPlanningTypeRaw, el.eventNote, el.eventDate)
				});
			}
			const mapCustomerVisit = (datas = []) => {
				return datas.map(el => createTableCustomerVisit(el.callPlanningEventId, el.customer?.outletId, el.customer?.outletName, el.customer?.outletClass[0]?.classCode, el.callPlanningTypeRaw, el.customer?.outletSoldTo?.designtion, el.eventDate, el.customer?.outletSoldTo?.outletName, el.customer?.outletClass[0]?.frequency, el.customer?.outletCode, el.customer?.soldOutletCode, el.customer?.outletStreet1, el.customer?.outletStreet2, el.customer?.outletStreet3, el.customer?.outletStreet4, el.customer?.outletStreet5));
			}

			const tableCustomer = mapCustomerVisit(payload.data.success ? payload.data.data.visitCustomer : []);
			const tableOther = mapOtherWork(payload.data.success ? payload.data.data.otherWork : []);

			return {
				...state,
				planOfMonth: objTemp,
				tableCustomer: tableCustomer,
				tableOther: tableOther
			}
		}
		case callPlanningActionTypes.SUMMARY_DATA: {
			const mapDate = (arr = []) =>
				arr.map(el => createSummaryData(el.class, el.reach, el.volume, el.notPlanned));
			return {
				...state,
				summaryData: payload.data.success ? mapDate(payload.data.data) : []
			}
		}
		case callPlanningActionTypes.REVIEW_DATA: {
			const sortInPlanned = (planned = '') => {
				return planned.split(',')
					.sort((a, b) => compareDate(a, b))
					.join(' ,');
			}

			const sortPlanned = (a, b) => {
				const firstItemA = a.split(',')[0];
				const firstItemB = b.split(',')[0];

				return compareDate(firstItemA, firstItemB);
			}

			const compareDate = (a, b) => {
				return new Date(a) - new Date(b);
			}

			const mapDate = (arr = []) => {
				return arr.map(el => {
					const planned = sortInPlanned(el.planned)

					return createReviewData(el.class, el.customerName, el.frequency, planned, el.status)
				}).sort((a, b) => sortPlanned(a.planned, b.planned));
			}

			const data = mapDate(payload.data.data?.items);

			return {
				...state,
				reviewData: payload.data.success ? data : []
			}
		}
		case callPlanningActionTypes.UNPLAN_DATA: {
			const mapDate = (arr = []) =>
				arr.map(el => createUnPlanData(el.class, el.customerName, el.frequency, el.outletSoldTo?.outletName));

			return {
				...state,
				unPlanData: payload.data.success ? mapDate(payload.data.data?.items) : []
			}
		}
		case callPlanningActionTypes.CUSTOMER_CLASS_DATA: {
			const mapCustomerClass = (datas = []) => {
				return [...new Set(datas.map(el => el.outletClass.map(o => o.classCode)).flat())].sort().map((element, index) => createOptions(element, index));
			}

			return {
				...state,
				customerClass: mapCustomerClass(payload.data.success ? payload.data.data?.items : []),
			}
		}
		case callPlanningActionTypes.CUSTOMER_TYPE_DATA: {
			const mapCustomerType = (datas = []) => {
				return [...new Set(datas.map(el => el.designtion))].filter(o => !!o).map((element, index) => createOptions(element, index));
			}

			return {
				...state,
				customerType: mapCustomerType(payload.data.success ? payload.data.data?.items : [])
			}
		}
		case callPlanningActionTypes.VISIT_CUSTOMER_DATA: {
			const mapCustomerVisit = (datas = []) =>
				datas.map(el => createMenuCustomerVisit(
					el.isNonOutlet,
					el.outletCity,
					el.outletId,
					el.outletName,
					el.soldOutletCode,
					el.outletSoldTo.outletName,
					el.outletClass[0]?.classCode,
					el.outletClass[0]?.outletClassFrequency.Actual,
					el.outletClass[0]?.outletClassFrequency.Target,
					CallPlanningConstant.CUSTOMER_TYPE.CUSTOMER.name,
					el.designtion,
					el.soldOutletCode,
					el.outletCode,
					el.outletStreet1,
					el.outletStreet2,
					el.outletStreet3,
					el.outletStreet4,
					el.outletStreet5,
				)
				);

			if (payload.getProvince === false) {
				return {
					...state,
					customerVisit: mapCustomerVisit(payload.data.success ? payload.data.data?.items : [])
				}
			} else {
				return {
					...state,
					customerVisitFilteredData: mapCustomerVisit(payload.data.success ? payload.data.data?.items : []),
					customerVisit: mapCustomerVisit(payload.data.success ? payload.data.data?.items : [])
				}
			}


		}
		case callPlanningActionTypes.OTHER_WORK_DATA: {
			const mapOtherWork = (datas = []) => datas.map(el => createMenuOtherWork(el.otherworkId, el.otherworkName, CallPlanningConstant.CUSTOMER_TYPE.OTHERWORK.name, el.leaveDay));

			return {
				...state,
				otherWork: mapOtherWork(payload.data.success ? payload.data.data?.items : [])
			}
		}
		case callPlanningActionTypes.CITY_DATA: {

			return {
				...state,
				city: payload.data.success ? payload.data.data : []
			}
		}
		case callPlanningActionTypes.ADDRESS_DATA: {

			return {
				...state,
				address: payload.data.success ? payload.data.data : []
			}
		}
		case callPlanningActionTypes.OUTLET_VISIT_CUSTOMER_DATA: {
			return {
				...state,
				outletVisitCustomerData: payload.data.success ? payload.data.data : {}
			}
		}
		case callPlanningActionTypes.RESET_DATA: {
			return initialState
		}

		case 'RESET_PLAN_MONTH': {
			return {
				...state,
				planOfMonth: {}
			}
		}

		default: return state
	}
}